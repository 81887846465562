import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import { Link } from 'gatsby';

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019() {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
            <Link to="/wspolnota/emaus">Powrót do strony wspólnoty Emaus</Link>
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/communitiesSlides/emaus/emaus-rekolekcje-odnowy-wiary-2020.png'} alt={'rekolekcje odnowy wyiary'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
